import React from "react";
import "./internal.css";
import Header from "../components/Common/header";
import LastSection from "../components/Common/lastSection";
import Footer from "../components/Common/footer";
import PageNotFounds from "../components/PP-and-TC/pagenotfound";
import "./zubair.css";

function PageNotFoundError() {
  return (
    <React.Fragment>
      <Header />
      <main>
        <PageNotFounds />
        <LastSection />
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default PageNotFoundError;
