import React from "react";
import Header from "./components/Common/header";
import Exclusive from "./components/Home/Exclusive";
import HomeBanner from "./components/Home/homeBanner";
import PhasesSection from "./components/Common/phasesSection";
import LogosSection from "./components/Home/logosSection";
import ReportsSection from "./components/Home/reportsSection";
import LastSection from "./components/Common/lastSection";
import Footer from "./components/Common/footer";
import HelmetMetaData from "./components/Common/HelmetMetaData";

function App(props) {
  return (
    <React.Fragment>
      <HelmetMetaData></HelmetMetaData>
      <Header />
      <main>
        <HomeBanner />
        <Exclusive />
        <PhasesSection {...props} extraClass='phases-new-section' />
        <LogosSection />
        <ReportsSection />
        <LastSection />
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default App;
