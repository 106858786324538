import frontendapiServices from './../../services/frontendapiServices'
export const GET_INVOLVED = "GET_INVOLVED";
const getInvolved = () => async (dispatch, oldState) => {
    try {
        const frontendapiService = new frontendapiServices();
        const involved = await frontendapiService.getAllInvolved();
        dispatch({
            type: GET_INVOLVED,
            renderData: involved,
        })
    }
    catch (error) {
        console.error(`Error found: ${error}`);
    }
}

export default getInvolved;