import frontendapiServices from './../../services/frontendapiServices'
export const GET_PROJECT_SHOWCASE = 'GET_PROJECT_SHOWCASE';
const getProjectShowcase = () => async (dispatch, oldSate) => {
    try {
        const frontendapiService = new frontendapiServices()
        const allProjectShowcase = await frontendapiService.getAllProjectShowcase();
        dispatch({
            type: GET_PROJECT_SHOWCASE,
            renderData: allProjectShowcase,
        })

    }
    catch (error) {

    }
}
export default getProjectShowcase;