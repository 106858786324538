import { config } from '../config'
import Axios from 'axios'
import moment from 'moment'

export default class frontendapiServices {

    async getAllPhaseDetails() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-phase-details`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllPhaseDetailsSA() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-phase-details-SA`)
            console.log(response,"sadasdsadtest");
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllHiringCompanies() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-hiring-companies`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllfriendsOfCodegirls() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-friends-of-codegirls`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllCodegirlsSponsor() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-codegirls-sponsor`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllBrainsBehindCG() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-brains-behind-cg`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllProjectShowcase() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-project-showcase`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllInvolved() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-involved`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getallBlogs(params = false) {
        try {
            let abc = '';
            //console.log(params, abc);
            if(params){
                //console.log(params['date']);
                let cat = params['category'];
                let author = params['author'];
                let search = params['search'];
                let date = (params['date'] != "") ? moment(params['date']).valueOf() : "";
                abc = "?category="+cat+"&author="+author+"&search="+search.trim()+"&date="+date;
            }
            const response = await Axios.get(`${config.API_URL}get-all-blogs${abc}`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getBlogDetails(id) {
        try {
            const response = await Axios.get(`${config.API_URL}get-blog-details/${id}`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getallCategories() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-categories`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getallAuthors() {
        try {
            const response = await Axios.get(`${config.API_URL}get-all-authors`)
            if (response.data.hasOwnProperty('status')) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
}
