import React, { Component, useEffect } from "react";
import NavbarDropdown from "react-navbar-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faBars,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { Link , useLocation} from "react-router-dom";
import Popup from "../popup/popup";

const DesktopMenu = ({ children }) => {
  const isDesktopMenu = useMediaQuery({ minWidth: 1025 });
  return isDesktopMenu ? children : null;
};

const RespMenu = ({ children }) => {
  const isRespMenu = useMediaQuery({ maxWidth: 1024 });
  return isRespMenu ? children : null;
};

// const scrollToHashElement = (hash) => {
//   if (hash) {
//     const element = document.getElementById(hash.substring(1));
//     if (element) {
//       element.scrollIntoView({ behavior: "smooth" });
//     }
//   }
// };

class Header extends Component {
  render() {
    // const location = useLocation();
  
    // useEffect(() => {
    //   if (location.hash) {
    //     scrollToHashElement(location.hash);
    //   }
    // }, [location]);
    return (
      <>
        <header>
          <div className="logo-container column-3">
            <div className="f-left">
              <Link to="/">
                <img src="/images/CG-new-logo.svg" alt="codegirls-logo" />
              </Link>
              <Link to="/award-winning" className="award-winning tooltip">
                <img src="/images/award-winning.svg" alt="award-winning" />
                <span>For Details Click Here‌ ‌ </span>
              </Link>
            </div>
          </div>
          <DesktopMenu>
            <div className="nav-container column-6">
              <nav>
                <Link to="/become-a-codegirl" className="nav-item tooltip">
                  Become a CodeGirl
                  <span>
                    Learn‌ ‌Coding‌ ‌and‌ ‌valuable‌ ‌Business‌ ‌skills‌ ‌that‌
                    ‌will‌ ‌kickstart‌ ‌your‌ ‌career!‌ ‌{" "}
                  </span>
                </Link>
                <Link
                  to="/become-a-codegirl-south-africa"
                  className="nav-item tooltip"
                >
                  Become a CodeGirl SA
                  <span>
                    Learn‌ ‌Coding‌ ‌and‌ ‌valuable‌ ‌Business‌ ‌skills‌ ‌that‌
                    ‌will‌ ‌kickstart‌ ‌your‌ ‌career!‌ ‌{" "}
                  </span>
                </Link>
                <Link to="/hire-a-codegirl" className="nav-item tooltip">
                  Hire a Codegirl
                  <span>
                    Hire talented graduates from CodeGirls and promote gender
                    diversity and inclusion in tech!
                  </span>
                </Link>
                <Link to="/sponsorship" className="nav-item tooltip">
                  Sponsor a CodeGirl
                  <span>
                    Be a CodeGirls sponsor and help us improve gender parity in
                    the technology industry!
                  </span>
                </Link>
                <div className="nav-item browse-more">
                  Browse More
                  <FontAwesomeIcon icon={faCaretDown} fixedWidth />
                  <ul className="dropdown-menu">
                    <li className="drop-menu-item">
                      <Link to="/about-us">About CodeGirls</Link>
                    </li>
                    <li className="drop-menu-item">
                      <Link to="/blog" className="tooltip">
                        Blog
                        <span>
                          Explore the vast array of insightful articles by our
                          brilliant contributors!
                        </span>
                      </Link>
                    </li>
                    <li className="drop-menu-item">
                      <Link to="/become-a-trainer" className="tooltip">
                        Become a Trainer
                        <span>
                          Join our Bootcamp and help us improve gender diversity
                          in the tech industry!
                        </span>
                      </Link>
                    </li>
                    <li className="drop-menu-item">
                      <Link to="#">
                        Coverage
                        <FontAwesomeIcon icon={faCaretRight} fixedWidth />
                      </Link>
                      <ul className="dropdown-menu-two">
                        <li className="drop-menu-item">
                          <Link to="/in-the-press" className="tooltip">
                            In The Press
                            <span>
                              Take a look at how our Bootcamp’s success is
                              featured in the press.
                            </span>
                          </Link>
                        </li>
                        <li className="drop-menu-item">
                          <Link to="/media-gallery" className="tooltip">
                            Media Gallery
                            <span>
                              View our program's success and progress through
                              our immersive photo gallery.
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="drop-menu-item">
                      <a
                        href="/#contact"
                        // onClick={() => scrollToHashElement("#contact")}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            {/* <div className="contact-container column-3">
            <div className="f-right">
              <Link className="email-icon" onClick={() => window.location = 'mailto:info@consulnet.net'}>
                <img src="/images/email.svg" alt="Email-logo" />
                info@consulnet.net
              </Link>
              <Link className="tel-icon" onClick={() => window.location = 'tel:0333-3654348'}>
              <img src="/images/telephone-call.svg" alt="Telephone-logo" />
                0333-3654348
              </Link>
            </div>
          </div> */}
          </DesktopMenu>
          <RespMenu>
            <NavbarDropdown>
              <NavbarDropdown.Toggle>
                <NavbarDropdown.Open>
                  <FontAwesomeIcon
                    icon={faBars}
                    fixedWidth
                    className="resp-baricon"
                  />
                </NavbarDropdown.Open>
                <NavbarDropdown.Close>
                  <FontAwesomeIcon
                    icon={faBars}
                    fixedWidth
                    className="resp-baricon"
                  />
                </NavbarDropdown.Close>
              </NavbarDropdown.Toggle>
              <NavbarDropdown.Menu
                className="responsive-menu"
                between="10px"
                align="right"
              >
                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to="/become-a-codegirl" className="nav-item tooltip">
                    Become a CodeGirl
                    <span>
                      Learn‌ ‌Coding‌ ‌and‌ ‌valuable‌ ‌Business‌ ‌skills‌
                      ‌that‌ ‌will‌ ‌kickstart‌ ‌your‌ ‌career!‌ ‌{" "}
                    </span>
                  </Link>
                </NavbarDropdown.Item>
                <NavbarDropdown.Item className="drop-menu-item">
                  <Link
                    to="/become-a-codegirl-south-africa"
                    className="nav-item tooltip"
                  >
                    Become a CodeGirl SA
                    <span>
                      Learn‌ ‌Coding‌ ‌and‌ ‌valuable‌ ‌Business‌ ‌skills‌
                      ‌that‌ ‌will‌ ‌kickstart‌ ‌your‌ ‌career!‌ ‌{" "}
                    </span>
                  </Link>
                </NavbarDropdown.Item>

                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to="/hire-a-codegirl" className="nav-item tooltip">
                    Hire a Codegirl
                    <span>
                      Hire talented graduates from CodeGirls and promote gender
                      diversity and inclusion in tech!
                    </span>
                  </Link>
                </NavbarDropdown.Item>

                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to="/sponsorship" className="nav-item tooltip">
                    Sponsor a CodeGirl
                    <span>
                      Be a CodeGirls sponsor and help us improve gender parity
                      in the technology industry!
                    </span>
                  </Link>
                </NavbarDropdown.Item>

                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to="/about-us">About CodeGirls</Link>
                </NavbarDropdown.Item>

                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to="/become-a-trainer" className="tooltip">
                    Become a Trainer
                    <span>
                      Join our Bootcamp and help us improve gender diversity in
                      the tech industry!
                    </span>
                  </Link>
                </NavbarDropdown.Item>

                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to="/in-the-press" className="tooltip">
                    In The Press
                    <span>
                      Take a look at how our Bootcamp’s success is featured in
                      the press.
                    </span>
                  </Link>
                </NavbarDropdown.Item>
                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to="/media-gallery" className="tooltip">
                    Media Gallery
                    <span>
                      View our program's success and progress through our
                      immersive photo gallery.
                    </span>
                  </Link>
                </NavbarDropdown.Item>
                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to="/blog" className="tooltip">
                    Blog
                    <span>
                      Explore the vast array of insightful articles by our
                      brilliant contributors!
                    </span>
                  </Link>
                </NavbarDropdown.Item>
                <NavbarDropdown.Item className="drop-menu-item">
                  <Link to={`${process.env.PUBLIC_URL}/#contact`}>
                    Contact Us
                  </Link>
                </NavbarDropdown.Item>

                {/* <NavbarDropdown.Item className="drop-menu-item">
                <Link
                  className="email-icon"
                  to={{ pathname: "mailto:info@codegirls.pro" }}
                >
                  {" "}
                  <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                  info@codegirls.pro
                </Link>
              </NavbarDropdown.Item>

              <NavbarDropdown.Item className="drop-menu-item">
                <Link
                  className="tel-icon"
                  to={{ pathname: "tel:+92-21-34557174" }}
                >
                  <FontAwesomeIcon icon={faPhoneAlt} fixedWidth />
                  +92-21-34557174
                </Link>
              </NavbarDropdown.Item> */}
              </NavbarDropdown.Menu>
            </NavbarDropdown>
          </RespMenu>
        </header>
        <Popup />
      </>
    );
  }
}

export default Header;
