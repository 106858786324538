import { combineReducers } from 'redux';

import phaseDetails from './phaseDetails';
import hiringCompanies from './hiringCompanies';
import friendsofCodegirls from './friendsofCodegirls';
import involved from './getInvolved';
import codegirlsSponsor from './codegirlsSponsor';
import brainsBehindCG from './BrainsBehindCG';
import projectShowcase from './ProjectShowcase';
import blogs from './Blogs';


const reducer = combineReducers({
    phaseDetails,
    hiringCompanies,
    friendsofCodegirls,
    codegirlsSponsor,
    brainsBehindCG,
    projectShowcase,
    blogs,
    involved,
})

export default reducer;