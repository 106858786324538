import React from "react"

function Exclusive() {
    return (
        <section className="section codegirls-exclusive">
            <div className="container">
                <div className="flex-row">
                    <div className="column-7">
                        <p className="para-2">CodeGirls is an exclusively women-only coding
          Bootcamp with one goal,</p>
                        <h2>“to improve the gender parity in
            <span> Pakistan's</span> tech industry”.</h2>
                        <p>At CodeGirls, our aim is to empower young girls with coding and business skills, which will help them kick start their careers, become financially independent, and excel in the tech industry!</p>
                        <p><strong>ConsulNet Corporation</strong> founded the program with its partners <strong>United Global Initiative</strong> and <strong>WomenInTechPK</strong>. CodeGirls is funded by some of the leading tech giants, influencers, tech community members, and business moguls. </p>
                    </div>
                    <div className="column-5">
                        <div className="img-container">
                            <img src="images/map-pakistan.svg" alt="Pakistan Map" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Exclusive;

