import React, { Component } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import getBrainsBehindCG from './../../store/Action/BrainsBehindCG';

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <FontAwesomeIcon
      icon={faArrowRight}
      fixedWidth
      className={"arrows arrow-right"}
      style={{
        ...style,
        cursor: "pointer",
        zIndex: "99",
        fontSize: "20px",
        color: "#fff",
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <FontAwesomeIcon
      icon={faArrowLeft}
      fixedWidth
      className={"arrows arrow-left"}
      style={{
        ...style,
        cursor: "pointer",
        zIndex: "99",
        fontSize: "20px",
        color: "#fff",
        // position: "absolute",
        // top: "-20px"
      }}
      onClick={onClick}
    />
  );
}
class ContactDetails extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.getBrainsBehindCG();
  }
  render() {
    let settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      slidesToScroll: 1,
      adaptiveHeight:true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    const allTabs = this.props.brainsBehindCG.map((contact, index) => {
      return (
        <div className="single-bbcg-slide" key={index}>
          <div className="quotes-container">
            <p>{contact.quote}</p>
          </div>
          <div className="author-details">
            <h3>{contact.name}</h3>
            <p className="designation">{contact.designation}<span>{contact.subtitle}</span></p>
          </div>
        </div>
      )
    })
    return (
      allTabs.length > 0 ?
        <Slider {...settings}>
            {allTabs}
        </Slider>
        : null
    );
  }
}

const mapToStateProps = state => ({
  brainsBehindCG: state.brainsBehindCG.renderData
})
const mapToDispatchProps = (dispatch) => ({
  getBrainsBehindCG: () => {
    dispatch(getBrainsBehindCG())
  }
})
export default connect(mapToStateProps, mapToDispatchProps)(ContactDetails);
