import React, { Component } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { config } from './../../config';
import { Link } from "react-router-dom";


function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <FontAwesomeIcon
      icon={faArrowRight}
      fixedWidth
      className={"arrows arrow-right"}
      style={{
        ...style,
        cursor: "pointer",
        zIndex: "99",
        fontSize: "20px",
        color: "white",
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <FontAwesomeIcon
      icon={faArrowLeft}
      fixedWidth
      className={"arrows arrow-left"}
      style={{
        ...style,
        cursor: "pointer",
        zIndex: "99",
        fontSize: "20px",
        color: "white",
        position: "absolute",
      }}
      onClick={onClick}
    />
  );
}

class LogosSlider extends Component {
  render() {

    const maxValue = 42;
    const NumofDiv = Math.ceil(this.props.slideDetails.length / maxValue);
    let count = 0
    const SliderItems = [];
    for (let i = 1; i <= NumofDiv; i++) {
      SliderItems.push(
        <div className="logo-container" key={i} >
          {this.props.slideDetails.filter((value, index) => {
            if (index >= count && (maxValue * i) > index) {
              count++
              return value;
            }
          }).map((value, index) => {
            return (
              <div className="logo-item" key={index}>
                {
                  value[this.props.fieldNames.link] != ""
                    ?
                    <Link
                      to={{ pathname: value[this.props.fieldNames.link] }}
                      title={value[this.props.fieldNames.name]}
                      target="_blank">
                      <img
                        src={`${config.UPLOAD_DIR}${value[this.props.fieldNames.image]}`}
                        alt="value"
                      />
                    </Link>
                    :
                    <img
                    src={`${config.UPLOAD_DIR}${value[this.props.fieldNames.image]}`}
                      alt="value"
                      title={value[this.props.fieldNames.name]}
                    />
                }

              </div>
            );
          })}
        </div>
      )
    }
    let settings = {
      dots: false,
      infinite: false,
      speed: 1000,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    return (
      <Slider {...settings}>
        {SliderItems}
      </Slider>
    );
  }
}

export default LogosSlider;
