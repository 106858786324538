import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { config } from "../../config";

function Footer() {
  const initCaptcha = () => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(config.SITE_KEY, { action: "contactus" })
        .then(function (token) {
          if (token) {
            document.querySelector("#_recaptcha").value = token;
          }
        });
    });
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${config.SITE_KEY}`;
      script.addEventListener("load", initCaptcha);
      document.body.appendChild(script);
    }
  });
  return (
    <footer className="codegirls-footer">
      <div className="container">
        <div className="flex-row">
          <div className="column-8">
            <div className="image-container">
              <Link to="/">
                <img src="/images/CG-Site-Logo.png" alt="codegirls-logo" />
              </Link>
            </div>
            <div className="footer-list">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="dash">
                  <p>-</p>
                </li>
                <li>
                  <Link to="/about-us">About CodeGirls</Link>
                </li>
                <li className="dash">
                  <p>-</p>
                </li>
                <li>
                  <Link className="tooltip" to="/become-a-codegirl">
                    Become a CodeGirl
                    <span>
                      Learn Coding and valuable Business skills that will
                      kickstart your career!
                    </span>
                  </Link>
                </li>
                <li className="dash">
                  <p>-</p>
                </li>
                <li>
                  <Link className="tooltip" to="/become-a-trainer">
                    Become a Trainer
                    <span>
                      Join our Bootcamp and help us improve gender diversity in
                      the tech industry!
                    </span>
                  </Link>
                </li>
                <li className="dash">
                  <p>-</p>
                </li>
                <li>
                  <Link to="/in-the-press">Coverage</Link>
                </li>
                <li className="dash">
                  <p>-</p>
                </li>
                <li>
                  <a href="/#contact">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="copy-right-text">
              <p>
                Copyright 2024 |{"  "}
                <Link
                  onClick={() =>
                    (window.location = "https://www.genetechsolutions.com")
                  }
                  rel="noreferrer noopener"
                >
                  genetechsolutions.com
                </Link>{" "}
                {"  "}–{"  "}
                <Link
                  onClick={() =>
                    (window.location = "https://www.consulnet.net")
                  }
                  rel="noreferrer noopener"
                >
                  ConsulNet Corporation
                </Link>
                {"  "}| All Rights Reserved.
              </p>
            </div>
            <div className="privacy-text footer-list">
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="dash">
                  <p>-</p>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms and Conditions </Link>
                </li>
              </ul>
            </div>
            <div className="social-icons footer-list">
              <Link
                onClick={() =>
                  (window.location =
                    "https://www.facebook.com/CodeGirlsbyConsulNetCorp/")
                }
                rel="noreferrer noopener"
              >
                <img src="/images/facebook-grey.png" alt="facebook" />
              </Link>
              <Link
                onClick={() =>
                  (window.location = "https://twitter.com/CodeGirlsKhi")
                }
                rel="noreferrer noopener"
              >
                <img src="/images/twitter.svg" alt="twitter" />
              </Link>
              <Link
                onClick={() =>
                  (window.location =
                    "https://www.linkedin.com/company/codegirlskhi/")
                }
                rel="noreferrer noopener"
              >
                <img src="/images/linked-in-grey.png" alt="linked in" />
              </Link>
            </div>
          </div>
          <div className="column-4">
            <div className="image-container">
              <img src="/images/NoPoverty.svg" alt="No Poverty" />
              <img src="/images/QualityEducation.svg" alt="Quality Education" />
              <img src="/images/GenderEquality.svg" alt="Gender Equality" />
              <img src="/images/DecentWork.svg" alt="DecentWork" />
              <img src="/images/Partnerships.svg" alt="Partnerships" />
            </div>
            <div className="united_nation_para">
              <p>
                Working towards these United Nations Sustainable Development
                Goals (SDGs)
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
