import { GET_PHASEDETIALS } from '../Action/phasedetials';

const phaseDetails = (state = { renderData: [] }, action) => {
    switch (action.type) {
        case GET_PHASEDETIALS:
            return {
                ...state,
                renderData: action.renderData
            }
        default:
            return state;

    }
}

export default phaseDetails;