import { validateAll, validations } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from './../config'
import Axios from 'axios'

export default class EmailServices {
    async contactUs(data) {
        console.log(data)
        const rules = {
            name: [validations.required(), validations.regex(['^[a-zA-Z ]+$'])],
            email: 'required|email',
            phone: [validations.required(), validations.regex(['^[+]*[0-9]{0,2}[\s]{0,1}[-]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]{0,9}$']), validations.min([11])],
            message: 'required|min:10'
        }
        try {
            await validateAll(data, rules, messages)
            const { email, name, phone, message } = data;
            const response = await Axios.post(`${config.API_URL}get-in-touch`, { email, name, phone, message })
            if (response.data.hasOwnProperty('errors')) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            console.log(validation_error)
            const tmp_errors = {};
            if (!validation_error.hasOwnProperty('errors')) {
                validation_error.forEach(function (error, index) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
    async hireDeveloper(data) {
        const rules = {
            name: [validations.required(), validations.regex(["^[A-Za-z0-9_ ]*$"])],
            companyName: [validations.required(), validations.regex(["^[A-Za-z0-9_ ]*$"])],
            companySite: 'required|url',
            workEmail: 'required|email',
            workPhone: [validations.required(), validations.regex(['^[+]*[0-9]{0,2}[\s]{0,1}[-]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]{0,9}$']), validations.min([11])],
            workArrangement: 'required',
            jobTitle: [validations.required(), validations.regex(['^[a-zA-Z ]+$'])],
            requiredSkills: 'required',
            MinimumEducation: [validations.required(), validations.regex(['^[a-zA-Z ]+$'])],
            applyByDate: [validations.required(), validations.before(['2999-12-31'])]
            // jobDescription: 'required',
        };
    
        try {
            console.log('Validating data...', data); // Debugging line
            await validateAll(data, rules, messages);
    
            // Debugging values of data.resume and data.jobDescription
            console.log('data.resume:', data.resume); 
            console.log('data.jobDescription:', data.jobDescription);
    
            if (data.resume == null && data.jobDescription.trim() == "") {
                console.log('Resume or job description is required'); // Debugging line
                const abc = { jobDescription: 'This field is required.' };
                throw abc;
            }
    
            var formData = new FormData();
            for (let key in data) {
                if (key === "resume" || key === "errors" || key === "success") {
                    continue;
                }
                formData.set(key, data[key]);
            }
            formData.append('resume', data.resume);
    
            console.log('FormData prepared:', formData); // Debugging line
    
            const response = await Axios({
                method: 'post',
                url: `${config.API_URL}hire-a-developer`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
    
            console.log('API Response:', response.data); // Debugging line
    
            if (response.data.hasOwnProperty('errors')) {
                throw response.data;
            }
            return response.data;
        } catch (error) {
            console.log('Error:', error); // Debugging line
    
            const tmp_errors = {};
            if (Array.isArray(error)) {
                error.forEach(function (error, index) {
                    tmp_errors[error.field] = error.message;
                });
                if (data.resume == null && data.jobDescription.trim() == "") {
                    tmp_errors['jobDescription'] = 'This field is required.';
                }
            } else if (error.errors) {
                for (let key in error.errors) {
                    tmp_errors[key] = error.errors[key];
                }
            } else {
                console.error('Unexpected error format:', error); // Debugging line
            }
            throw tmp_errors;
        }
    }
    
    async hireaTrainer(data) {
        const rules = {
            fullname: [validations.required(), validations.regex(['^[a-zA-Z ]+$'])],
            organization: [validations.required(), validations.regex(['^[a-zA-Z ]+$'])],
            designation: [validations.required(), validations.regex(['^[a-zA-Z ]+$'])],
            email: 'required|email',
            city: [validations.required(), validations.regex(['^[a-zA-Z ]+$'])],
            position: [validations.required(), validations.regex(['^[a-zA-Z ]+$'])],
            resume: 'required',
        }
        try {
            await validateAll(data, rules, messages)
            var formData = new FormData()
            for (let key in data) {
                if (key == "resume" || key == "errors" || key == "success") {
                    continue;
                }
                formData.set(key, data[key])
            }
            formData.append('resume', data.resume)

            const response = await Axios({
                method: 'post',
                url: `${config.API_URL}become-a-trainer`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data;',
                }
            });
            //  const response = await Axios.post(`${config.API_URL}/become-a-trainer`, formData)
            if (response.data.hasOwnProperty('errors')) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            console.log(validation_error)
            const tmp_errors = {};
            if (!validation_error.hasOwnProperty('errors')) {
                validation_error.forEach(function (error, index) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
}
