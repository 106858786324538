import frontendapiServices from './../../services/frontendapiServices'
export const GET_CODEGIRLS_SPONSOR = "GET_CODEGIRLS_SPONSOR";

const getCodegirlsSponsor = () => async (dispatch, oldState) => {
    try {
        const frontendapiService = new frontendapiServices();
        const codegirlsSponsor = await frontendapiService.getAllCodegirlsSponsor();
        dispatch({
            type: GET_CODEGIRLS_SPONSOR,
            renderData: codegirlsSponsor,
        })
    }
    catch (error) {
        console.error(`Error found: ${error}`);
    }
}
export default getCodegirlsSponsor;
