import React, { Component } from "react";
import { Link } from "react-router-dom";

class PageNotFounds extends Component {
  state = {};
  render() {
    return (
      <section className="page-not-found">
        <div className="container">
          <div className="alert-section">
            <div className="text-align">
              <h1><span>4</span>0<span>4</span></h1>
              <h4>Unfortunately, this page does not exist. Please check your URL or return to the <Link to="/">Home Page</Link>.</h4>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PageNotFounds;
