import frontendapiServices from './../../services/frontendapiServices'
export const GET_PHASEDETIALS = 'GET_PHASEDETIALS';

const getPhaseDetials = () => async (dispatch, oldstate) => {
    try {
        const frontendapiService = new frontendapiServices();
        const phaseDetials = await frontendapiService.getAllPhaseDetails()
        dispatch({
            type: GET_PHASEDETIALS,
            renderData: phaseDetials
        })
    }
    catch (error) {
        console.error(`Error found: ${error}`);
    }
}

export default getPhaseDetials;