import { GET_FRIENDS_OF_CODEGIRLS } from './../Action/friendsofCodegirls';

const friendsOfCodegirls = (state = { renderData: [] }, action) => {

    switch (action.type) {
        case GET_FRIENDS_OF_CODEGIRLS:
            return {
                ...state,
                renderData: action.renderData,
            }
        default:
            return state;
    }
}

export default friendsOfCodegirls;