import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export default function HelmetMetaData(props) {
   let location = useLocation();
   let currentUrl = "https://codegirls.consulnet.net" + location.pathname;
   let quote = props.quote !== undefined ? props.quote : "";
   let title = props.title !== undefined ? props.title : "CodeGirls";
   let image = props.image !== undefined ? props.image : "https://codegirls.consulnet.net/images/CG-social-share-logo.png";
   let description = props.description !== undefined ? props.description  : "Empowering Women Through Vocational IT & Business Training";
   let hashtag = props.hashtag !== undefined ? props.hashtag : "#codegirls";
return (
    <Helmet>
        <title>{title}</title>
        <meta property="type" content="website" />
        <meta property="url" content={currentUrl} />
        <meta property="title" content={title} />
        <meta property="quote" content={quote} />
        <meta name="description" content={description} />
        <meta property="image" content={image} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:quote" content={quote} />
        <meta property="og:hashtag" content={hashtag} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content="CodeGirls" />
        <meta property="og:description" content={description} />
    </Helmet>
);
}