import React, { Component } from "react";
import { connect } from 'react-redux';
import getInvolved from './../../store/Action/getInvolved';
import { Link } from "react-router-dom";
import { config } from './../../config';
class GetInvolvedSection extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.getInvolved();
  }
  render() {
    return (
    
          <div className="flex-row">
            {
              this.props.involved.map((value,index) =>{
                return(
                  <div className="column-6">
                  <div className="single-pdf-book">
                    <div className="contain">
                      <img
                        src={`${config.UPLOAD_DIR}${value.Involved_Image}`}
                        alt={value.Involved_Name}
                      />
                      <div className="overlay">
                        <Link
                          to={`${config.UPLOAD_DIR}${value.Involved_Url}`}
                          className="icon tooltip"
                          title="Download"
                          target="_blank"
                        >
                          <img src="images/download_image.png" alt="" />
                          <span>{value.Involved_Description}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                )


              })
            }
            {/* <div className="column-6">
                    <div className="single-pdf-book">
                      <div className="contain">
                        <img
                          src="images/CodeGirls-AssessmentReport2022.png"
                          alt="Program Assessment Report"
                        />
                        <div className="overlay">
                          <Link
                            to="/pdfs/CG-AssessmentReport2022.pdf"
                            className="icon tooltip"
                            title="Download"
                            target="_blank"
                          >
                            <img src="images/download_image.png" alt="" />
                            <span>Take a look at our Program’s success in empowering girls through coding and business skills, and see how far we have come from where we started!</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  
                  {/* <div className="column-6">
                    <div className="single-pdf-book pdf-book-sec">
                      <div className="contain">
                        <img
                          src="images/CodeGirls-SponsorshipProposal2022.png"
                          alt="Sponsorship Proposal"
                        />
                        <div className="overlay">
                          <Link
                            to="/pdfs/CG-SponsorshipProposal2022.pdf"
                            className="icon tooltip"
                            title="Download"
                            target="_blank"
                          >
                            <img src="images/download_image.png" alt="" />
                            <span>Check out our Program’s Sponsorship package and how we are leading the fight for change and bringing gender diversity and inclusion to the tech industry!</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}

              
                  
          </div>
        
    );
  }
}
const mapStateToProps = (state) => {
  return {
    involved: state.involved.renderData,
  }
}
const mapDispatchToProps = dispatch => ({
  getInvolved: () => {
    dispatch(getInvolved());
  },
 
})
export default connect(mapStateToProps, mapDispatchToProps)(GetInvolvedSection);
