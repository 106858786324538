import { GET_INVOLVED } from './../Action/getInvolved';

const getInvolved = (state = { renderData: [] }, action) => {

    switch (action.type) {
        case GET_INVOLVED:
            return {
                ...state,
                renderData: action.renderData,
            }
        default:
            return state;
    }
}

export default getInvolved;