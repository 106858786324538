import { GET_BRAINS_BEHIND_CG } from './../Action/BrainsBehindCG';


const brainsBehindCG = (state = { renderData: [] }, action) => {
    switch (action.type) {
        case GET_BRAINS_BEHIND_CG:
            return {
                ...state,
                renderData: action.renderData
            }
        default:
            return state;
    }

}
export default brainsBehindCG;