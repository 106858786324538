import React, { Component } from "react";
import ContactDetails from "./contactDetails";
import EmailServices from "./../../services/email";
import { config } from "../../config";
// import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

class LastSection extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: "",
      errors: "",
      success: "",
      disable: false,
    };
  }
  onChangeEvent = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onSubmithandler = async (event) => {
    const EmailService = new EmailServices();
    event.preventDefault();
    this.setState({
      disable: true,
    });
    try {
      const response = await EmailService.contactUs(this.state);
      //   document.getElementById('submit').onclick = function() {
      //     this.disabled = false;
      // };

      this.setState({
        name: "",
        phone: "",
        email: "",
        message: "",
        errors: "",
        success: response.message,
        disable: false,
      });
    } catch (errors) {
      console.log(errors);
      this.setState({ errors, disable: false });
    }
    //   document.getElementById('submit').onclick = function() {
    //     this.disabled = true;
    // }
  };

  render() {
    return (
      <>
        <section className="section-5 contact-section" id="contact">
          <div className="container">
            <div className="flex-row">
              <div className="column-5">
                <ContactDetails />
              </div>
              <div className="column-6 form-column">
                <h3> Get In Touch With Us!</h3>
                <h5>
                  Find out more about the program, discuss future possibilities,
                  sponsor, contribute, or register!{" "}
                </h5>
                <form onSubmit={this.onSubmithandler}>
                  <div className="form-row-1">
                    <div>
                      {this.state.errors.name && (
                        <small className="warning-text">
                          {this.state.errors.name}
                        </small>
                      )}
                      <input
                        onChange={this.onChangeEvent}
                        name="name"
                        type="text"
                        value={this.state.name}
                      />
                      <label>Your Name* </label>
                    </div>
                    <div>
                      {this.state.errors.phone && (
                        <small className="warning-text">
                          {this.state.errors.phone}
                        </small>
                      )}
                      <input
                        onChange={this.onChangeEvent}
                        name="phone"
                        value={this.state.phone}
                      />
                      <label>Phone* </label>
                    </div>
                  </div>
                  <div>
                    {this.state.errors.email && (
                      <small className="warning-text">
                        {this.state.errors.email}
                      </small>
                    )}
                    <input
                      type="email"
                      onChange={this.onChangeEvent}
                      name="email"
                      value={this.state.email}
                    />
                    <label>Email Address* </label>
                  </div>
                  <div className="form-message">
                    {this.state.errors.message && (
                      <small className="warning-text">
                        {this.state.errors.message}
                      </small>
                    )}
                    <input
                      type="text"
                      onChange={this.onChangeEvent}
                      name="message"
                      value={this.state.message}
                    />
                    <label>Message* </label>
                  </div>
                  <div className="form-submit">
                    <input
                      type="submit"
                      name="submit"
                      value="Submit"
                      id="submit"
                      disabled={this.state.disable}
                    />
                  </div>
                  {this.state.success && (
                    <small className="success-text">{this.state.success}</small>
                  )}
                  <input type="hidden" name="recaptcha" id="_recaptcha" />
                </form>
              </div>
            </div>
          </div>
        </section>
        <section
          style={{
            backgroundColor: "rgba(255, 255, 255, 1)",
            display: "flex",
            justifyContent: "center",
            padding:"20px 0",
          }}
          className="location-sec"
        >
          <div sm={4} style={{ padding: "80px",marginRight: "80px" }} className="karachi-location">
            <h3
              style={{
                fontSize: "24px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Karachi
            </h3>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
                className="email-icon"
                onClick={() => (window.location = "mailto:info@consulnet.net")}
              >
                <img src="/images/email.svg" alt="Email-logo" />
                info@consulnet.net
              </Link>
            </div>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                  marginLeft: "0px",
                }}
                className="tel-icon"
                onClick={() => (window.location = "tel:0333-3654348")}
              >
                <img src="/images/telephone-call.svg" alt="Telephone-logo" />
                0333-3654348
              </Link>
            </div>
          </div>
          <div sm={4} style={{ padding: "80px" , marginRight: "80px"}} className="skardu-location">
            <h3
              style={{
                fontSize: "24px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Skardu
            </h3>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
                className="email-icon"
                onClick={() => (window.location = "mailto:info@consulnet.net")}
              >
                <img src="/images/email.svg" alt="Email-logo" />
                info@consulnet.net
              </Link>
            </div>
            <div>
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                  marginLeft: "0px",
                }}
                className="tel-icon"
                onClick={() => (window.location = "tel:0333-3654348")}
              >
                <img src="/images/telephone-call.svg" alt="Telephone-logo" />
                0333-3654348
              </Link>
            </div>
          </div>
          <div sm={4} style={{ padding: "80px"}} className="south-africa-location">
            <h3
              style={{
                fontSize: "24px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              South Africa
            </h3>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
                className="email-icon"
                onClick={() => (window.location = "mailto:info@codegirls.pro")}
              >
                <img src="/images/email.svg" alt="Email-logo" />
                info@codegirls.pro
              </Link>
            </div>
            <div>
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginBottom: "10px",
                  marginLeft: "0px",
                }}
                className="tel-icon"
                onClick={() => (window.location = "tel:+2781 451 5347")}
              >
                <img src="/images/telephone-call.svg" alt="Telephone-logo" />
                +2781 451 5347
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LastSection;
