import frontendapiServices from './../../services/frontendapiServices'
export const GET_HIRING_COMPANIES = "GET_HIRING_COMPANIES";

const getHiringCompanies = () => async (dispatch, oldstate) => {
    try {
        const frontendapiService = new frontendapiServices();
        const hiringCompanies = await frontendapiService.getAllHiringCompanies();
        dispatch({
            type: GET_HIRING_COMPANIES,
            renderData: hiringCompanies
        })
    }
    catch (error) {
        console.error(`Error found: ${error}`);
    }
}

export default getHiringCompanies;