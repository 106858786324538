
const prod_config = {
    API_URL: 'https://api.codegirls.consulnet.net/',
    SITE_KEY: '6LdVtoMUAAAAANm-nVlHFBtXJ4hyPqSMv9dhJ6ck',
    UPLOAD_DIR: '/images/uploads',
};
const dev_config = {
    API_URL: 'http://192.168.14.125:3334/',
    SITE_KEY: '6LdWx8EZAAAAAJ-dqKCX6NSyvRNhhgX7P_1COF0c',
    UPLOAD_DIR: '/assets/uploads/',
};

export const config = process.env.NODE_ENV === 'development' ? dev_config : prod_config;