import React, { Component } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Popup() {
  return (
    <>
      <div className="popup-box desktop-popup-box" id="popup">
        <div className="popup-container">
          <div className="popup-logo-content">
            <div className="popup-logo">
              <img src="/images/popup-logo.svg" alt="Popup Logo" />
            </div>
            <div className="popup-content">
              <h3>empowering women</h3>
              <p>Through Vocational IT & Business Training</p>
            </div>
          </div>
          <div className="popup-main">
            <div className="popup-main-content">
              <p>
                We are thrilled to announce that{" "}
                <span>ConsulNet Corporation,</span> the driving force behind the
                transformative CodeGirls program, has received the
              </p>
              <h1>
                Coveted National <span>ICT award,</span>
              </h1>
              <p>
                reaffirming our commitment to fostering positive change in the
                tech industry and Pakistan's socio-economic landscape.
              </p>
              <div className="popup-main-btn">
                <Link to="/award-winning">READ MORE</Link>
              </div>
            </div>
          </div>
          <div className="cross" id="cross">
            <img src="/images/cross.png" alt="Cross" />
          </div>
        </div>
      </div>
      {/* <div className="popup-box mobile-popup-box" id="popup-mobile">
        <div className="popup-container">
          <div className="mobile-popup-image">
            <img src="/images/mobile-img.svg" alt="Image" />
          </div>
          <div className="popup-main">
            <div className="popup-main-content">
              <p>
                We are thrilled to announce that{" "}
                <span>ConsulNet Corporation,</span> the driving force behind the
                transformative CodeGirls program, has received the
              </p>
              <h1>
                Coveted National <span>ICT award,</span>
              </h1>
              <p>
                reaffirming our commitment to fostering positive change in the
                tech industry and Pakistan's socio-economic landscape.
              </p>
              <h3>empowering women</h3>
              <p>Through Vocational IT & Business Training</p>
              <div className="popup-main-btn">
                <Link to="/award-winning">READ MORE</Link>
                <img src="/images/popup-logo.svg" alt="Popup Logo" />
              </div>
            </div>
          </div>
          <div className="cross" id="cross-mobile">
            <img src="/images/cross.png" alt="Cross" />
          </div>
        </div>
      </div> */}
    </>
  );
}
