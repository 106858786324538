import frontendapiServices from './../../services/frontendapiServices'
export const GET_BRAINS_BEHIND_CG = 'GET_BRAINS_BEHIND_CG';
const getBrainsBehindCG = () => async (dispatch, oldSate) => {
    try {
        const frontendapiService = new frontendapiServices()
        const allBrainsBehindCG = await frontendapiService.getAllBrainsBehindCG();

        dispatch({
            type: GET_BRAINS_BEHIND_CG,
            renderData: allBrainsBehindCG,
        })

    }
    catch (error) {

    }
}
export default getBrainsBehindCG;