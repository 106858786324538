import React, { Component } from "react";
import GetInvolvedSection from "./getInvolved";
import { Link } from "react-router-dom";

class ReportsSection extends Component {
  state = {};
  render() {
    return (
      <section className="section-4 reportSec">
        <div className="container">
          <div className="flex-row">
            <div className="column-4">
              <div className="brains-list">
                <p>How to Participate</p>
              </div>
              <h2>
                get<span> involved</span>
              </h2>
              <p>
                Take a look at our Program’s success in empowering young girls
                through coding and business skills, and see how far we have come
                from where we started and how we are leading the fight for
                change and bringing inclusion to the tech industry!
              </p>
              <div className="endButtons-d">
                <Link to="/sponsorship" className="btn tooltip">
                Sponsor a CodeGirl
                 <span >Be a CodeGirls sponsor and help us improve gender parity in the technology industry!</span>
              </Link>
                <Link to="/hire-a-codegirl" className="btn tooltip">
                  Hire a CodeGirl
                   <span >Empower young girls and give them a boost by providing opportunities in the booming tech industry!</span>
                </Link>
              </div>
            </div>
            <div className="column-8">
              <div className="homepage-pdf-section">
               

                  <GetInvolvedSection />


                  {/* <div className="column-6">
                    <div className="single-pdf-book">
                      <div className="contain">
                        <img
                          src="images/CodeGirls-AssessmentReport2022.png"
                          alt="Program Assessment Report"
                        />
                        <div className="overlay">
                          <Link
                            to="/pdfs/CG-AssessmentReport2022.pdf"
                            className="icon tooltip"
                            title="Download"
                            target="_blank"
                          >
                            <img src="images/download_image.png" alt="" />
                            <span>Take a look at our Program’s success in empowering girls through coding and business skills, and see how far we have come from where we started!</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column-6">
                    <div className="single-pdf-book pdf-book-sec">
                      <div className="contain">
                        <img
                          src="images/CodeGirls-SponsorshipProposal2022.png"
                          alt="Sponsorship Proposal"
                        />
                        <div className="overlay">
                          <Link
                            to="/pdfs/CG-SponsorshipProposal2022.pdf"
                            className="icon tooltip"
                            title="Download"
                            target="_blank"
                          >
                            <img src="images/download_image.png" alt="" />
                            <span>Check out our Program’s Sponsorship package and how we are leading the fight for change and bringing gender diversity and inclusion to the tech industry!</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  
              </div>
            </div>
            <div className="endButton-r">
              <Link to="/sponsorship" className="btn tooltip">
                Sponsor a CodeGirl
                 <span >Be a CodeGirls sponsor and help us improve gender parity in the technology industry!</span>
              </Link>
              <Link to="/hire-a-codegirl" className="btn tooltip">
                Hire a CodeGirl
                 <span >Empower young girls and give them a boost by providing opportunities in the booming tech industry!</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ReportsSection;
