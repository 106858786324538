import frontendapiServices from './../../services/frontendapiServices'
export const GET_BLOGS = 'GET_BLOGS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_AUTHORS = 'GET_AUTHORS';
export const GET_BLOG_DETAILS = 'GET_BLOG_DETAILS';
export const getBlogs = (data = false) => async (dispatch, oldSate) => {
    try {
        //console.log(data);
        const frontendapiService = new frontendapiServices()
        const allBlogs = await frontendapiService.getallBlogs(data);
        dispatch({
            type: GET_BLOGS,
            renderData: allBlogs,
        })

    }
    catch (error) {

    }
}
export const getCategories = () => async (dispatch, oldSate) => {
    try {
        const frontendapiService = new frontendapiServices()
        const allCategories = await frontendapiService.getallCategories();
        dispatch({
            type: GET_CATEGORIES,
            renderData: allCategories,
        })

    }
    catch (error) {
        console.log('test');
    }
}
export const getAuthors = () => async (dispatch, oldSate) => {
    try {
        const frontendapiService = new frontendapiServices()
        const allAuthors = await frontendapiService.getallAuthors();
        dispatch({
            type: GET_AUTHORS,
            renderData: allAuthors,
        })

    }
    catch (error) {

    }
} 
export const getBlogDetails = (id) => async (dispatch, oldSate) => {
    try {
        const frontendapiService = new frontendapiServices()
        const blogDetails = await frontendapiService.getBlogDetails(id);
        dispatch({
            type: GET_BLOG_DETAILS,
            renderData: blogDetails,
        })

    }
    catch (error) {

    }
} 
