import frontendapiServices from './../../services/frontendapiServices'
export const GET_FRIENDS_OF_CODEGIRLS = "GET_FRIENDS_OF_CODEGIRLS";
const getFriendsOfCodegirls = () => async (dispatch, oldState) => {
    try {
        const frontendapiService = new frontendapiServices();
        const friendsOfCodegirls = await frontendapiService.getAllfriendsOfCodegirls();
        dispatch({
            type: GET_FRIENDS_OF_CODEGIRLS,
            renderData: friendsOfCodegirls,
        })
    }
    catch (error) {
        console.error(`Error found: ${error}`);
    }
}

export default getFriendsOfCodegirls;