import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import getPhaseDetials from './../../store/Action/phasedetials';
import PhaseColumn from "./PhaseColumn";
class PhasesSection extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.getPhaseDetials();
  }
  render() {
    const phaseColumn = this.props.phasesDetails.map((phasesDetail, index) => {
      return (<PhaseColumn Phase={phasesDetail} key={index} />)
    });
    return (

      this.props.phasesDetails.length > 0 ?
        <section
          className={`section-2 ${this.props.extraClass ?? ''}`}
          style={{ backgroundColor: this.props.styling }}
        >
          <div className="container">
            <div className="flex-row">
              {phaseColumn}
              {
                this.props.location.pathname !== "/become-a-codegirl" ?
                  <div className="btn-container">
                    <Link to="/become-a-codegirl" className="btn tooltip first-btn">
                      Become a CodeGirl
                      <span >Learn Coding and valuable Business skills that will kickstart your career!</span>
                    </Link>
                    <Link to="/about-us" className="btn tooltip">
                    Phases and Schedule
                      <span >Check out our boot camp’s schedules, phases, and details on the upcoming cohorts!</span>
                    </Link>


                  </div>
                  : null

              }
            </div>
          </div>
        </section>
        : null
    );
  }
}
const mapStatetoProps = store => ({ phasesDetails: store.phaseDetails.renderData})
const mapDispatchtoProps = dispatch => ({
  getPhaseDetials: () => {
    dispatch(getPhaseDetials());
  }
})

export default connect(mapStatetoProps, mapDispatchtoProps)(PhasesSection);
