import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import routing from "./routes";
import { Provider } from 'react-redux';
import store from './store';
const ProviderWithRouting = () => (<Provider store={store}>
    {routing}
</Provider>);
ReactDOM.render(<ProviderWithRouting />, document.getElementById("root"));
serviceWorker.unregister();
