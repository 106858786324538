import { GET_HIRING_COMPANIES } from '../Action/hiringCompanies';

const hiringCompanies = (state = { renderData: [] }, action) => {
    switch (action.type) {
        case GET_HIRING_COMPANIES:
            return {
                ...state,
                renderData: action.renderData
            }
        default:
            return state;

    }
}

export default hiringCompanies;