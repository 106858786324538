import { GET_CODEGIRLS_SPONSOR } from './../Action/codegirlsSponsor'

const codegirlsSponsor = (state = { renderData: [] }, action) => {
    switch (action.type) {
        case GET_CODEGIRLS_SPONSOR:
            return {
                ...state,
                renderData: action.renderData,
            }
        default:
            return state;
    }
}

export default codegirlsSponsor;