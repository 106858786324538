import { GET_BLOGS, GET_BLOG_DETAILS, GET_CATEGORIES, GET_AUTHORS } from './../Action/Blogs';


const blogs = (state = { renderData: [] }, action) => {
    switch (action.type) {
        case GET_BLOGS:
            return {
                ...state,
                renderData: action.renderData
            }
        case GET_CATEGORIES:
            return {
                ...state,
                renderCategData: action.renderData
            }
        case GET_AUTHORS:
            return {
                ...state,
                renderAuthorData: action.renderData
            }
        case GET_BLOG_DETAILS:
            return {
                ...state,
                renderDetailsData: action.renderData
            }
        default:
            return state;
    }

}
export default blogs;