import React, { Component } from "react";
import Tabs from "../Common/tabs";
import LogosSlider from "./logosSlider";
import { connect } from 'react-redux';
import TopFive from "../../components/HireADeveloper/topFive";
//import getHiringCompanies from './../../store/Action/hiringCompanies';
import getFriendsOfCodegirls from './../../store/Action/friendsofCodegirls';
import getCodegirlsSponsor from './../../store/Action/codegirlsSponsor';
import { Link } from "react-router-dom";
class LogosSection extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    //this.props.getHiringCompanies();
    this.props.getFriendsOfCodegirls();
    this.props.getCodegirlsSponsor();
  }
  render() {
    return (
      <section className="section-3 logos-section">
        <div className="container">
          <div className="flex-row">
            <div className="company-list">
            <h2>Friends Of Codegirls</h2>
                <LogosSlider
                    fieldNames={
                      {
                        descrption: 'focDescription',
                        image: 'focImage',
                        link: 'focLink',
                        name: 'focName'
                      }}
                    slideDetails={this.props.friendsofCodegirls}
                    
                  />
                  <Link to="/hire-a-codegirl" className="nav-item tooltip">
                      Hire a Codegirl
                     <span >Hire talented graduates from CodeGirls and promote gender diversity and inclusion in tech!</span>
                  </Link>
              {/* <Tabs>
                <div label="Top Hiring Companies">
                  <TopFive />
                </div>

                <div label="Friends of CodeGirls">
                  <div className="flex-row">
                    <div className="company-content">
                      <h2>
                        friends
                        <br /> of <br />
                        codegirls
                      </h2>
                      <p>
                      Get to know our Sponsors, who are an excellent helping hand in the quest of empowering girls through Coding and Business Training!
                      </p>
                      <Link target="_blank" to="/sponsorship" className="nav-item tooltip">
                      Sponsor a CodeGirl
                     <span >Be a CodeGirls sponsor and help us improve gender parity in the technology industry!</span>
                      </Link>
                    </div>
                    <div className="logo-column">
                      <LogosSlider
                        fieldNames={
                          {
                            descrption: 'CGSDescription',
                            image: 'CGSImage',
                            link: 'CGSLink',
                            name: 'CGSName'
                          }}
                        slideDetails={this.props.codegirlsSponsors}
                      />
                    </div>
                    <Link
                      target="_blank" to="https://www.consulnet.net/pay"
                      rel="noreferrer noopener"
                      className="nav-item endButton-r tooltip"
                    >
                      Sponsor a CodeGirl
                       <span >Be a CodeGirls sponsor and help us improve gender parity in the technology industry!</span>
                    </Link>
                  </div>
                </div>

                <div label="Collaborators of CodeGirls ">
                  <div className="flex-row">
                    <div className="company-content friends-of-cg">
                      <h2>
                        collaborators 
                        <br /> of <br />
                        codegirls
                      </h2>
                      <p>
                      Check out our rockstar collaborators who are taking our Coding Bootcamp to the next level through their invaluable support, resources, and opportunities!
                      </p>
                    </div>
                    <div className="logo-column">
                      <LogosSlider
                        fieldNames={
                          {
                            descrption: 'focDescription',
                            image: 'focImage',
                            link: 'focLink',
                            name: 'focName'
                          }}
                        slideDetails={this.props.friendsofCodegirls}
                      />
                    </div>
                  </div>
                </div>
              </Tabs> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    //hiringCompanies: state.hiringCompanies.renderData,
    friendsofCodegirls: state.friendsofCodegirls.renderData,
    codegirlsSponsors: state.codegirlsSponsor.renderData,
  }
}
const mapDispatchToProps = dispatch => ({
  // getHiringCompanies: () => {
  //   dispatch(getHiringCompanies());
  // },
  getFriendsOfCodegirls: () => {
    dispatch(getFriendsOfCodegirls());
  },
  getCodegirlsSponsor: () => {
    dispatch(getCodegirlsSponsor());
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(LogosSection);
