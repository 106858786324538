import React from "react"

function InnerDetail({ PhaseDetails, ...restPropsDetails }) {
    return (
        <div className="inner-content-phase">
            <p className="para-2">{PhaseDetails.workshopName} ({PhaseDetails.workshopHours} Hours)</p>
            <div className="detail-content" dangerouslySetInnerHTML={{ __html: PhaseDetails.workshopDescription }}></div>
        </div>

    )
}

export default InnerDetail;

