import { GET_PROJECT_SHOWCASE } from './../Action/ProjectShowcase';


const projectShowcase = (state = { renderData: [] }, action) => {
    switch (action.type) {
        case GET_PROJECT_SHOWCASE:
            return {
                ...state,
                renderData: action.renderData
            }
        default:
            return state;
    }

}
export default projectShowcase;