import React, { Component } from "react";
import { connect } from 'react-redux';
import LogosSlider from "./../Home/logosSlider";
import { Link } from "react-router-dom";
import getHiringCompanies from './../../store/Action/hiringCompanies';

class TopFive extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.getHiringCompanies();
  }
  render() {
    return (
      <div className="flex-row">
        <div className="company-content">
          <h2>
            top
            <br /> hiring <br />
            companies
          </h2>
          <p>
            Our Graduates are quickly being absorbed by the Tech
            industry and so far they have been recruited by some
            renowned names such as:
          </p>
          <Link target="_blank" to="/hire-a-codegirl" className="endButton-d tooltip">
            Hire a CodeGirl
              <span >Empower young girls and give them a boost by providing opportunities in the booming tech industry!</span>
          </Link>
        </div>
        <div className="logo-column">
          <LogosSlider
            fieldNames={
              {
                descrption: 'HCDescription',
                image: 'HCImage',
                link: 'HCLink',
                name: 'HCName'
              }}
            slideDetails={this.props.hiringCompanies}
          />
        </div>
        <Link target="_blank" to="/hire-a-codegirl" className="endButton-r tooltip">
          Hire a CodeGirl
            <span >Empower young girls and give them a boost by providing opportunities in the booming tech industry!</span>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hiringCompanies: state.hiringCompanies.renderData,
  }
}
const mapDispatchToProps = dispatch => ({
  getHiringCompanies: () => {
    dispatch(getHiringCompanies());
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(TopFive);
